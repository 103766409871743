import React from 'react'
import { Link } from 'gatsby'
import CountUp from 'react-countup'
import OnVisible from 'react-on-visible'

import { Layout, Helmet, Section, Container, Image } from 'components'

// class NumberCount extends React.Component {
//   state = {
//     count: 0,
//   }

//   componentDidMount() {
//     this.setState(prevState => ({
//       count: prevState.count + 1,
//     }))
//   }

//   render() {
//     let { number } = this.props
//     let { count } = this.state
//     return <span>{count}</span>
//   }
// }

const IndexPage = () => (
	<Layout hidePhone className='index-page'>
		<Helmet
			title='Nomade, gestion immobilière'
			description='Notre mission, assurer la qualité de vie de nos locataires, dans nos immeubles résidentiels, en offrant des espaces locatifs novateurs et un service-client exemplaire.'
		/>

		<div className='first-section'>
			<div className='main-background'>
				<Image />
			</div>
			<div className='main-content'>
				<h1>
					VOUS CHERCHEZ UN LOGEMENT ?
					<br />
				</h1>
				<div className='koso-buttons no-before'>
					<Link to='/residentiel'>VOIR NOS IMMEUBLES</Link>
				</div>
			</div>
		</div>

		<Section grey>
			<Container>
				<p className='intro'>
					<b>Nomade Gestion Immobilière</b> est une équipe de gestionnaires de plus<b>de 30 ans d'expérience</b>{' '}
					dans le domaine de l'immobilier.
					<br />
					<br />
					Notre portefeuille immobilier comprend <b>plus de 720 logements, et bientôt 850, </b> dans la ville de Québec.
					<br />
					<br />
					Notre expérience nous permet d'offrir des espaces locatifs de confort maximum à des prix
					compétitifs.
				</p>
			</Container>
		</Section>
		<Section>
			<Container>
				<div className='flex highlights'>
					<div>
						<h2>
							<CountUp start={0} end={23}>
								{({ countUpRef, start }) => (
									<OnVisible onChange={start}>
										<span ref={countUpRef} />
									</OnVisible>
								)}
							</CountUp>
						</h2>
						<p>immeubles</p>
					</div>
					<div>
						<h2>
							<CountUp start={0} end={720}>
								{/* // decimal= // decimals={3}
								// suffix={' pi²'} */}
								{({ countUpRef, start }) => (
									<OnVisible onChange={start}>
										<span ref={countUpRef} />
									</OnVisible>
								)}
							</CountUp>
						</h2>
						<p>logements</p>
					</div>
				</div>
			</Container>
		</Section>
	</Layout>
)

export default IndexPage

// export const pageQuery = graphql`
//
// `
